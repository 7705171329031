import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RtPlatformService} from "./rt-platform.service";



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    RtPlatformService,
  ]
})
export class RtPlatformModule { }
